import * as React from "react"
import { useState } from "react"
import { Link } from "gatsby"
import {
  Form,
  FormGroup,
  FormControl,
  Button,
} from "react-bootstrap"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "../css/_section-contact.scss"
import { errorFindSTA } from "../functions/errorFind"

const breadData = [
  {
    id: 1,
    ink: "/",
    label: "HOME",
  },
  {
    id: 3,
    ink: "/security-threat-audit/",
    label: "Security Threat Audit",
  },
]

const STA = () => {
  const [subscribe, setSubscribe] = useState(false)
  const [errors, setErrors] = useState({})
  const [fields, setFields] = useState({
    firstName: "",
    lastName: "",
    job: "",
    company: "",
    email: "",
    subscribe: "",
  })

  function submit(e) {
    e.preventDefault()
    const openErrors = errorFindSTA(fields)
    if (Object.keys(openErrors).length > 0) {
      setErrors(openErrors)
    } else {
      var xhr = new XMLHttpRequest()
      var url =
        "https://api.hsforms.com/submissions/v3/integration/submit/7763866/ce428cff-acaf-423a-89ec-8537a7774302"

      var data = {
        fields: [
          {
            name: "firstname",
            value: fields.firstName,
          },
          {
            name: "lastname",
            value: fields.lastName,
          },
          {
            name: "jobtitle",
            value: fields.job,
          },
          {
            name: "company",
            value: fields.company,
          },
          {
            name: "email",
            value: fields.email,
          },
          {
            name: "subscribe",
            value: subscribe ? true : false,
          },
        ],
      }

      var final_data = JSON.stringify(data)

      xhr.open("POST", url)
      // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
      xhr.setRequestHeader("Content-Type", "application/json")

      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {
          window.location.href = "/us/gated-asset-form-success"
        } else if (xhr.readyState === 4 && xhr.status === 400) {
          console.log(xhr.responseText) // Returns a 400 error the submission is rejected.
        } else if (xhr.readyState === 4 && xhr.status === 403) {
          console.log(xhr.responseText) // Returns a 403 error if the portal isn't allowed to post submissions.
        } else if (xhr.readyState === 4 && xhr.status === 404) {
          console.log(xhr.responseText) //Returns a 404 error if the formGuid isn't found
        }
      }

      // Sends the request

      xhr.send(final_data)
    }
  }

  return (
    <Layout>
      <Seo title="Security Threat Audit" />
      <div className="breadcrumbs">
        <nav className="container-fluid p-sides-med">
          <ul>
            {breadData.map(breadcrumb => (
              <li key={breadcrumb.id}>
                <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
      <section className="section-audit grid--bg --for-it-ops-3 ">
        <div className="container">
          <div className="row">
            <div className="section-col-1  col-sm-6">
              <h3>SECURITY THREAT AUDIT</h3>
              <h6>
                EXPERIENCE THE CONFIDENT SECURITY OF BEING A VIGILANT CLIENT
              </h6>
              <p>
                During our industry leading Security Threat Audit, Vigilant’s
                Hunt Team proactively monitors your network for signs of
                anomalous activity indicative of active compromise,
                misconfigurations and other notable security risks. After,
                you'll receive a written report containing the findings,
                classifying your threat level and providing you recommended
                courses of action.
              </p>
              <p>
                <strong>
                  Our team observes, investigates and verifies the following:
                </strong>
              </p>
              <ul>
                <li>Database access attempts</li>
                <li>Review of database connections</li>
                <li>Remote Desktop access attempts</li>
                <li>SSH Access Attempts</li>
                <li>Inbound FTP connections</li>
                <li>
                  Review of connections and authentication of credentials in the
                  clear
                </li>
                <li>
                  PN Connections – specifically locations of access,
                  single-factor detection and access control
                </li>
                <li>Web Proxy Discovery</li>
                <li>DWeb Proxy Discovery</li>
                <li>Communicating applications and vulnerabilities</li>
                <li>Outdated OS communication</li>
                <li>
                  All communications in and out of your environment over time
                </li>
                <li>
                  Contact information and call escalation paths with your team
                </li>
                <li>
                  All connection traffic for the time frame since installation
                </li>
                <li>
                  All alerts for the time frame our sensor has been fully
                  operational
                </li>
                <li>
                  Perform a review and proactive hunts of data in your
                  environment
                </li>
              </ul>
            </div>

            <div className="section-col col-sm-6">
              <div className="container">
                <div className="box" style={{ width: "75%" }}>
                  <Form>
                    <Form.Group className="mb-3" controlId="formFirstName">
                      <Form.Control
                        value={fields.firstName}
                        className="bg-white"
                        type="fName"
                        placeholder="First Name"
                        onChange={e =>
                          setFields({ ...fields, firstName: e.target.value })
                        }
                      />

                      <span>{errors.firstName}</span>
                    </Form.Group>
                    <FormGroup
                      className="bg-white mb-3"
                      controlId="formLastName"
                    >
                      <FormControl
                        value={fields.lastName}
                        className="bg-white"
                        type="lName"
                        placeholder="Last Name"
                        onChange={e =>
                          setFields({ ...fields, lastName: e.target.value })
                        }
                      />
                      <span type="invalid">{errors.lastName}</span>
                    </FormGroup>
                    <Form.Group className="mb-3" controlId="formJobTitle">
                      <Form.Control
                        value={fields.job}
                        className="bg-white"
                        type="job"
                        placeholder="Job Title"
                        onChange={e =>
                          setFields({ ...fields, job: e.target.value })
                        }
                      />

                      <span>{errors.job}</span>
                    </Form.Group>
                    <FormGroup className="mb-3" controlId="formCompany">
                      <FormControl
                        value={fields.company}
                        className="bg-white"
                        type="jobTitle"
                        placeholder="Company"
                        onChange={e =>
                          setFields({ ...fields, company: e.target.value })
                        }
                      />
                      <span type="invalid">{errors.company}</span>
                    </FormGroup>
                    <FormGroup className="mb-3" controlId="formBasicEmail">
                      <FormControl
                        value={fields.email}
                        className="bg-white"
                        type="email"
                        placeholder="Business Email"
                        onChange={e =>
                          setFields({ ...fields, email: e.target.value })
                        }
                      />
                      <span type="invalid">{errors.email}</span>
                    </FormGroup>
                    <FormGroup>
                      <Form.Check
                        id="custom-switch"
                        label="Sign up to receive updates from Vigilant about our services, news and events. You can unsubscribe anytime."
                        onClick={() => setSubscribe(!subscribe)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Button
                        style={{
                          width: "100%",
                          backgroundColor: "transparent",
                        }}
                        className="nav-section--cta-btn"
                        variant="primary"
                        type="submit"
                        onClick={submit}
                      >
                        <h4>SIGN UP NOW</h4>
                      </Button>
                      <p className="pt-5 white">
                        <em>
                          Vigilant is committed to ensuring the security and
                          protection of the personal information that we
                          process, and to provide a compliant and consistent
                          approach to data protection. If you have any questions
                          related to our privacy policies, please contact
                          legal@vigilantnow.com
                        </em>
                      </p>
                    </FormGroup>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="breadcrumbs">
        <nav className="container-fluid p-sides-med">
          <ul>
            {breadData.map(breadcrumb => (
              <li key={breadcrumb.id}>
                <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </Layout>
  )
}

export default STA
